/* .liquidationTwo .home {
    background: #fafafa;
} */

.filters-by,
.sort-by {
    margin: 0 -5px;
}
.hiwSteps .hiwStpCard {
    margin: auto;
}
.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}
.swiper-button-prev,
.swiper-button-next {
    background-color: #fae077;
    color: #00269b;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 40%;
}

.liquidationTwo.hyperMicro .swiper-button-prev,
.liquidationTwo.hyperMicro .swiper-button-next {
    background-color: #fae077;
    color: #00269b;
}
.liquidationTwo .swiper-button-prev,
.liquidationTwo .swiper-button-next {
    background-color: #0fabe8;
    color: #fff;
}
.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 25px;
}
.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.liquidationTwo .home .bannerContainer {
    padding-top: 25px;
}

.liquidationTwo .home .auctionListCnt {
    position: relative;
    margin-top: 0px;
    background: transparent;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.liquidationTwo .home .auctionListCnt .auctionListTitle {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2e2e2e;
    text-align: left;
    text-transform: initial;
    margin: 45px 0;
    position: relative;
    text-align: left;
}

.liquidationTwo .home .auctionListCnt .auctionListTitle::after {
    position: absolute;
    display: block;
    content: '';
    bottom: -5px;
    left: 0%;
    width: 60px;
    background: var(--secColor);
    height: 3px;
    top: initial;
    right: 0;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.liquidationTwo .homeCnt .homeLt {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
    margin-top: 210px;
}

.liquidationTwo .homeCnt .homeRt {
    width: 100%;
}

.liquidationTwo .homeLt h3 {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 25px;
    position: relative;
}

.liquidationTwo .homeLt h3::after {
    position: absolute;
    content: '';
    bottom: -10px;
    left: 0;
    height: 3px;
    width: 80px;
    background: var(--primColor);
}

.liquidationTwo .homeLt .homeCatg {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner .MuiCheckbox-root {
    display: none;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner > div {
    width: 100%;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner > div > .customCheckbox,
.liquidationTwo .homeLt .homeCatg label,
.liquidationTwo .homeLt .homeCatg .MuiTypography-root {
    width: 100%;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner .catCount {
    height: 25px;
    font-size: 14px;
    background: #e9e9e9;
    color: #2e2e2e;
    padding: 0px 15px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.liquidationTwo .homeLt .homeCatg .homeCatgInner label {
    margin: 0;
}

.liquidationTwo .homeRt .MuiTabs-fixed {
    text-align: center;
}

.liquidationTwo .homeRt .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.liquidationTwo .homeRt .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.liquidationTwo .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.liquidationTwo .homeRt .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: var(--secColor);
}

.liquidationTwo .homeRt .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--secColor);
}

.liquidationTwo .homeRt .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.liquidationTwo .homeRt .MuiTabs-root {
    margin-bottom: 45px;
}

.liquidationTwo .homeContact {
    text-align: center;
    background: #f7f7f7;
    padding: 45px 0;
}

.liquidationTwo .homeContact h2 {
    font-size: 22px;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 10px;
}

.liquidationTwo .homeContact p {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
    margin-bottom: 20px;
}

.liquidationTwo .homeContact .subsBox {
    height: 50px;
    width: 400px;
    margin: 0 auto;
}

.liquidationTwo .homeContact .subsBox input {
    height: inherit;
    border: none;
    width: calc(100% - 50px);
    padding-left: 15px;
}

.liquidationTwo .homeContact .subsBox button {
    height: inherit;
    background: var(--secColor);
    color: #fff;
    min-width: inherit;
    width: 50px;
    margin-top: -3px;
}
.homeCnt .toggleRespDrawer.MuiButton-root {
    display: none;
    z-index: 100;
}

.home .viewAllBtn {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 20px 0 10px;
}

.home .viewAllBtn hr {
    width: 45%;
}

.home .viewAllBtn button {
    color: var(--primColor);
    text-transform: initial;
    min-width: max-content;
    font-size: 18px;
}

.homeLoader .loadingCnt {
    display: block;
}

.homeLoader .loadingCnt .skeletonWrapper {
    box-shadow: none;
    margin: 15px;
    padding: 0;
}

.homeLoader .loadingCnt .listViewSkeleton {
    background: #ffffff;
}

.liquidationTwo .home .loadingCnt,
.homeAuctions.mobileView {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 15px;
}

.homeAuctions.mobileView .liquidationTwoTimerView h6:not(:last-child) {
    margin-right: 20px;
}

.homeAuctions.mobileView .liquidationTwoTimerView h6 {
    font-size: 18px;
}

.homeAuctions.mobileView .liquidationTwo .grid-card .list-title {
    font-size: 15px;
    height: 4.5ex;
}

.appBarWrapper .MuiAppBar-root {
    background: transparent;
    box-shadow: none;
}

.appBarWrapper .MuiAppBar-root .MuiTabs-root button .MuiTab-wrapper,
.appBarWrapper .MuiAppBar-root .MuiTabs-root button.MuiTab-textColorInherit {
    color: var(--primColor);
    opacity: 1;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    font-family: var(--primFont);
}

.liquidationTwo.hyperMicro .appBarWrapper .MuiAppBar-root .MuiTabs-root button .MuiTab-wrapper,
.liquidationTwo.hyperMicro
    .appBarWrapper
    .MuiAppBar-root
    .MuiTabs-root
    button.MuiTab-textColorInherit {
    font-weight: 700;
    font-size: 20px;
}

.liquidationTwo.hyperMicro .appBarWrapper .MuiTabs-indicator {
    background: var(--secColor);
}

.appBarWrapper .MuiTabs-indicator {
    display: block;
    background: var(--primColor);
}

.appBarWrapper .MuiTabs-flexContainer {
    border-bottom: none;
}

.appBarWrapper .MuiTab-textColorInherit.Mui-selected {
    background-color: transparent;
}

.liquidationTwo .home .MuiTypography-root {
    padding: 30px 0;
}

.liquidationTwo .noBidAboveDiv {
    height: 50px;
}
@media screen and (min-width: 768px) {
    .liquidationTwo .noBidDownDiv {
        height: 25px;
    }
}

.liquidationTwo .liquidationBuyCard .swiper {
    padding: 15px 3px 50px;
}

.liquidationTwo .explrMore .MuiButton-label {
    color: var(--primColor);
    font-weight: 600;
    font-size: 22px;
}

.liquidationTwo .home .divHdrTxt {
    font-size: 28px;
    font-weight: 600;
}

.liquidationTwo .home .hiwContainer {
    background: white;
    padding: 30px 0;
}

.liquidationTwo .home .hiwContainer h2 {
    font-size: 40px;
    font-weight: 600;
}

.liquidationTwo .home .hiwContainer .hdrInfoTxt {
    color: gray;
    font-weight: 600;
}

.liquidationTwo .home .hiwContainer .hiwSteps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    margin-top: 70px;
}

.liquidationTwo .home .hiwContainer .hiwSteps .hiwIcnWrp {
    width: 70px;
    height: 70px;
    margin-bottom: 25px;
}

.liquidationTwo .home .hiwContainer .hiwSteps .hiwIcnWrp svg {
    fill: var(--primColor);
    width: 100%;
    height: 100%;
}

.liquidationTwo .home .hiwContainer .hiwSteps .hiwTxtBdy h4 {
    font-size: 22px;
}

.liquidationTwo .home .hiwContainer .hiwSteps .hiwTxtBdy p {
    font-size: 15px;
    color: gray;
}

/* .liquidationTwo.hyperMicro .home .faqContainer {
    display: none;
} */

.liquidationTwo .home .faqContainer {
    background: white;
}

.liquidationTwo .home .faqContainer .divHdrTxt .extnlLnk {
    color: var(--primColor);
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
    text-decoration: none;
}

.liquidationTwo .home .faqContainer .fqLst {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.liquidationTwo .home .faqContainer .fqLst ul {
    list-style: none;
    padding-left: 0;
}

.liquidationTwo .home .faqContainer .fqLst li {
    margin: 22px 0;
    padding-left: 20px;
    position: relative;
}

.liquidationTwo .home .faqContainer .fqLst li::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0px;
    border-top: 7px solid transparent;
    border-left: 7px solid var(--primColor);
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.liquidationTwo.hyperMicro .home .faqContainer .fqLst li::before {
    border-left: 7px solid var(--secColor);
}

.siteBanner.multiple .slick-next:before,
.siteBanner.multiple .slick-next,
.siteBanner.multiple .slick-prev,
.siteBanner.multiple .slick-prev:before {
    top: 91%;
    z-index: 99;
    background: transparent;
    color: gray;
}

.siteBanner.multiple .slick-next:before,
.siteBanner.multiple .slick-prev:before {
    font-size: 28px;
}

.siteBanner.multiple .slick-next:before,
.siteBanner.multiple .slick-prev {
    left: unset;
    right: 50px;
}

.siteBanner.multiple .slick-prev:before,
.siteBanner.multiple .slick-next {
    right: 18px;
}

.newBanner {
    background-color: #0fabe8;
    height: 160px;
    margin-top: -20px;
}

.newBannerContent {
    color: #fff;
    top: 10px !important;
    left: 5px !important;
    right: 5px !important;
    text-align: center;
}

.newBannerContent h3 {
    font-weight: 700;
}

.newBannerContent h5 {
    font-weight: 600;
}

.newBannerButton {
    color: #fff;
    border: 2px solid #fff;
    border-radius: 8px;
    font-weight: 650;
    font-size: 20px !important;
    height: 40px;
    width: 280px !important;
    background-color: #0fabe8;
}

@media (max-width: 1400px) and (min-width: 800px) {
    .newBanner {
        background-color: #0fabe8;
        height: 160px;
        margin-top: -20px;
    }

    .newBannerContent {
        color: #fff;
        top: 10px !important;
        left: 10px !important;
        right: 10px !important;
        text-align: center;
    }

    .newBannerContent h3 {
        font-weight: 700;
        font-size: 24px;
    }

    .newBannerContent h5 {
        font-weight: 600;
        font-size: 16px;
    }

    .newBannerButton {
        color: #fff;
        border: 2px solid #fff;
        border-radius: 8px;
        font-weight: 650;
        font-size: 18px !important;
        height: 40px;
        width: 250px !important;
        background-color: #0fabe8;
    }
}

@media (max-width: 799px) and (min-width: 500px) {
    .newBanner {
        background-color: #0fabe8;
        height: 160px !important;
        margin-top: -20px;
    }

    .newBannerContent {
        color: #fff;
        top: 10px !important;
        left: 10px !important;
        right: 10px !important;
        text-align: center;
    }

    .newBannerContent h3 {
        font-weight: 600;
        font-size: 18px;
    }

    .newBannerContent h5 {
        font-weight: 500;
        font-size: 14px;
    }

    .newBannerButton {
        color: #fff;
        border: 2px solid #fff;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px !important;
        height: 40px;
        width: 200px !important;
        background-color: #0fabe8;
    }
}

@media (max-width: 499px) {
    .newBanner {
        background-color: #0fabe8;
        height: 160px !important;
        margin-top: -20px;
    }

    .newBannerContent {
        color: #fff;
        top: 10px !important;
        left: 5px !important;
        right: 5px !important;
        text-align: center;
    }

    .newBannerContent h3 {
        font-weight: 600;
        font-size: 16px;
    }

    .newBannerContent h5 {
        font-weight: 500;
        font-size: 12px;
    }

    .newBannerButton {
        color: #fff;
        border: 2px solid #fff;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px !important;
        height: 35px;
        width: 200px !important;
        background-color: #0fabe8;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .liquidationTwo .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

/* Responsive Code */

@media (min-width: 1024px) {
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .liquidationTwo .homeCnt .homeRt {
        max-width: 100%;
    }
    .liquidationTwo .home .auctionListCnt .auctionListTitle {
        text-align: left;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
        position: absolute;
        top: 72px;
        right: 60px;
        left: auto;
    }
    .liquidationTwo .homeCnt .homeLt {
        display: none;
    }
}
@media (max-width: 870px) {
    .auctionListCnt .appBarWrapper .MuiTabs-fixed {
        overflow-x: scroll !important;
    }
}
@media (max-width: 850px) {
    .liquidationTwo .homeRt .MuiTab-wrapper {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .homeCnt .toggleRespDrawer.MuiButton-root {
        top: 50px;
    }

    .siteBanner {
        margin-bottom: 0;
    }

    .liquidationTwo .homeRt .MuiTabs-fixed {
        text-align: left;
    }

    .liquidationTwo .home .auctionListCnt .auctionListTitle {
        margin: 20px auto;
    }

    .liquidationTwo .homeCnt .homeLt {
        max-width: 100%;
        position: inherit;
        margin-right: 0px;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .liquidationTwo .homeCnt .homeRt {
        max-width: 100%;
    }

    .liquidationTwo .homeCnt {
        flex-direction: column;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .Liquidation .home .siteBanner .bannerContent {
        top: 30px;
        left: 30px;
    }

    .Liquidation .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .Liquidation .home .siteBanner .bannerContent p {
        font-size: 15px;
        margin-bottom: 25px;
    }
    /* .Liquidation .home .auctionListCnt {
        margin-top: -90px;
    } */
    .Liquidation .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .liquidationTwo .homeRt .MuiTab-wrapper {
        font-size: 16px;
    }
    .liquidationTwo .homeRt .MuiTabs-fixed {
        overflow: scroll !important;
    }

    .liquidationTwo .home .siteBanner .bannerContent {
        top: 60px;
    }
}

@media (max-width: 600px) {
    .liquidationTwo .home .auctionListCnt .auctionListTitle {
        font-size: 22px;
    }

    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 30px;
    }
    .liquidationTwo .home .siteBanner .bannerContent h2 {
        font-size: 16px;
        margin-bottom: 10px;
        max-width: 210px;
        width: 100%;
        line-height: 22px;
        padding-bottom: 0px !important;
    }
    .liquidationTwo .home .siteBanner .bannerContent {
        position: absolute;
        top: 10px;
        left: 15px;
    }
    .home .viewAllBtn hr {
        width: 35%;
    }

    .liquidationTwo .home .bannerContainer {
        padding: 0 !important;
    }

    .liquidationTwo .textSliderWrapper {
        background: var(--secColor);
        color: white;
        text-align: center;
        font-size: 12px;
    }

    .siteBanner.multiple .slick-next:before,
    .siteBanner.multiple .slick-next,
    .siteBanner.multiple .slick-prev,
    .siteBanner.multiple .slick-prev:before {
        top: 85%;
        z-index: 99;
        background: transparent;
        color: gray;
    }

    .appBarWrapper .MuiAppBar-root .MuiTabs-root button .MuiTab-wrapper,
    .appBarWrapper .MuiAppBar-root .MuiTabs-root button.MuiTab-textColorInherit {
        font-size: 14px;
    }

    .liquidationTwo .home .hiwContainer .hiwSteps {
        grid-template-columns: 1fr;
    }

    .liquidationTwo .home .faqContainer .fqLst {
        font-size: 14px;
    }

    .liquidationTwo .home .faqContainer .divHdrTxt .extnlLnk {
        font-size: 13px;
        padding-left: 10px;
    }

    .liquidationTwo .home .hiwContainer .hiwSteps {
        text-align: center;
    }

    .liquidationTwo .home .hiwContainer .hiwSteps .hiwIcnWrp {
        width: 50px;
        height: 50px;
        margin-bottom: 25px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 475px) {
    .liquidationTwo .homeContact .subsBox {
        width: 90%;
    }
    .liquidationTwo .homeContact {
        padding: 25px 0;
    }
    .liquidationTwo .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
    .homeCnt .toggleRespDrawer.MuiButton-root {
        right: 15px;
        padding: 3px 10px;
    }
    .liquidationTwoTimerView h6 {
        font-size: 15px;
    }
    .liquidationTwoTimerView h6:not(:last-child) {
        margin-right: 20px;
    }
    .liquidationTwo .grid-card .list-title {
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .siteBanner {
        height: 250px;
        top: 40%;
    }
    .liquidationTwo .noBidAboveDiv {
        height: 47px;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
    .Liquidation .home .auctionListCnt {
        padding: 0;
        margin-top: 0;
    }
    .Liquidation .home {
        background: #fff;
    }
}
@media (max-width: 400px) {
    .liquidationTwo .noBidAboveDiv {
        height: 48px;
    }
}
@media (max-width: 371px) {
    .liquidationTwo .noBidAboveDiv {
        height: 48px;
    }
}
