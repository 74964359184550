.Liquidation .footer-main-standard {
    padding: 50px 0 0 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
}

.Liquidation .footer-main-standard a {
    color: var(--footerFontColor);
}

.Liquidation .footer-copy-right {
    padding: 20px 30px 10px;
    background: var(--footerColor);
}

.Liquidation .footer-copy-right p {
    margin: 0;
}

.Liquidation .stctPgWrpr a {
    word-break: break-all;
}

.Liquidation a.cvc-link {
    font-size: 15px;
}

.Liquidation .footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.Liquidation .footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.Liquidation .newsletter-wrapper {
    position: relative;
}
.Liquidation .footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.Liquidation .footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.Liquidation .footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.Liquidation .footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
    text-align: center !important;
}

.Liquidation .footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.Liquidation .footer-main-standard-social-list > li > a {
    display: block;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: inherit;
    opacity: 0.75;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
    filter: alpha(opacity=75);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    transition: 0.3s;
}

.Liquidation .footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.Liquidation .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: column;
}

.Liquidation .ftrDtlCntnr .footerLogo {
    width: 150px;
    height: 100%;
    object-fit: contain;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.Liquidation .ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.Liquidation .ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.Liquidation .ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.Liquidation .ftrDtlCntnr .fa,
.Liquidation .ftrDtlCntnr .fab,
.Liquidation .ftrDtlCntnr .fal,
.Liquidation .ftrDtlCntnr .far,
.Liquidation .ftrDtlCntnr .fas {
    font-size: 22px;
}

.Liquidation .footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: block;
}

.Liquidation .Liquidation .footerLinks {
    justify-content: center;
    line-height: 41px;
}

.Liquidation .footerLinks li:not(:last-child) {
    margin-bottom: 10px;
}

.Liquidation .footerLinks li a {
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
}

.Liquidation .ftrLgCntnr {
    max-width: 250px;
    height: 120px;
    width: 100%;
}

.Liquidation .ftrLgCntnr img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: top left;
}

.Liquidation .ftrLnkWrpr {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    max-width: 690px;
}

.Liquidation .lnkGrdTb h4 {
    color: var(--footerFontColor);
    font-weight: 600;
    font-size: 15px;
    opacity: 0.6;
}

.Liquidation .lnkGrdTb ul li {
    font-weight: normal;
}

.Liquidation .adrsFlds p {
    line-height: 27px;
    font-size: 14px;
}

@media (max-width: 991px) {
    .Liquidation .ftrLgCntnr {
        max-width: 120px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
    }

    .Liquidation .footerLinks {
        display: block;
    }

    .Liquidation .ftrWrprVw {
        flex-wrap: wrap;
    }

    .Liquidation .ftrLnkWrpr {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 20px;
    }

    .lgAdCntnr {
        margin-left: auto;
        margin-right: auto;
    }

    .Liquidation .adrsFlds p {
        line-height: 23px;
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .Liquidation .ftrDtlCntnr .footerLogo {
        width: 100px;
    }
    .Liquidation .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }
    .Liquidation .MultiSeller .ftrDtlCntnr {
        justify-content: center;
    }
}
